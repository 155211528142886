<template>
  <div id="Mbottom">
    <div class="addwe flex ALcenter JCcenter">
      <div>
        <div class="addwe-tit tac">加入我们，一起创造未来！</div>
        <div class="addwe-btn flex JCcenter">
          <div class="addwe-btn-ad tac" @click="goTijiao">立即加入</div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="box-tit tac">联系我们</div>
      <div class="box-size">
        <div class="box-size-tit">
          <div class="box-size-tit-head flex ALcenter">
            <img
              src="../../assets/m/icon1.png"
              class="box-size-tit-head-icon"
              alt=""
            />
            <div>公司地址</div>
          </div>
          <div class="box-size-tit-text">
            浙江省杭州市上城区彭埠街道中豪五星国际4幢9楼
          </div>
        </div>
      </div>
      <div class="box-size">
        <div class="box-size-tit">
          <div class="box-size-tit-head flex ALcenter">
            <img
              src="../../assets/m/icon2.png"
              class="box-size-tit-head-icon"
              alt=""
            />
            <div>电话联系</div>
          </div>
          <div class="box-size-tit-text">0571-56669944</div>
        </div>
      </div>

      <div class="box-img flex ALcenter JCcenter">
        <div>
          <img src="../../assets/icon/weixin.png" class="box-img-img" alt="" />
          <div class="box-img-text tac">官方微信公众号</div>
        </div>
      </div>
      <div class="box-texts tac">
        @Copyrights 2023-2024 浙江云茗科技有限公司
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    /**
     * 提交
     */
    goTijiao() {
      this.$router.push("/Mshenqing");
    },
  },
};
</script>

<style lang="less" scoped>
#Mbottom {
  width: 750px;
  background: #f2f9ff;
  .addwe {
    width: 750px;
    height: 232px;
    background: url(~@/assets/m/bottom1.png) no-repeat;
    background-size: 100% 100%;

    .addwe-tit {
      font-weight: 500;
      font-size: 34px;
      color: #353c58;
      margin-bottom: 16px;
    }

    .addwe-btn {
      width: 750px;
      .addwe-btn-ad {
        width: 192px;
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        color: #ffffff;
        background: #038ef2;
        border-radius: 8px 8px 8px 8px;
      }
    }
  }

  .box {
    width: 702px;
    margin: 0 24px;
    .box-tit {
      width: 702px;
      height: 130px;
      line-height: 130px;
      font-weight: 600;
      font-size: 36px;
      color: #353c58;
      border-bottom: 1px solid #dfe8ee;
    }

    .box-size {
      width: 670px;
      margin: 40px 16px 0;
      .box-size-tit {
        width: 670px;
        .box-size-tit-head {
          width: 670px;
          font-size: 26px;
          color: #353c58;
          margin-bottom: 12px;
          .box-size-tit-head-icon {
            width: 36px;
            height: 36px;
            margin-right: 4px;
          }
        }

        .box-size-tit-text {
          font-weight: 600;
          font-size: 28px;
          color: #353c58;
          line-height: 44px;
        }
      }
    }

    .box-img {
      width: 670px;
      height: 518px;
      border-bottom: 1px solid #dfe8ee;
      .box-img-img {
        width: 336px;
        height: 336px;
      }

      .box-img-text {
        width: 336px;
        height: 82px;
        line-height: 60px;
        font-size: 24px;
        color: #64698b;
      }
    }

    .box-texts {
      width: 670px;
      height: 244px;
      line-height: 160px;
      font-size: 24px;
      color: #353c58;
    }
  }
}
</style>