<template>
  <div id="mtabbar">
    <div class="pof flex JCspaceBetween ALcenter">
      <img
        src="../assets/m/logo.png"
        class="pof-img"
        @click="openthedoor"
        alt=""
      />
      <div class="flex ALcenter">
        <img
          src="../assets/m/more.png"
          class="pof-icon"
          @click="openShow"
          alt=""
        />
        <div class="pof-box tac white" @click="goTijiao">免费试用</div>
      </div>
    </div>
    <div class="hezi"></div>

    <van-popup v-model="show" position="top">
      <div class="box">
        <div v-for="(item, index) in menu" :key="index">
          <div class="box-li flex ALcenter JCspaceBetween">
            <div @click="goMenu(item)">{{ item.name }}</div>
            <img
              class="box-li-icon"
              v-if="item.child.length != 0"
              :src="item.show ? arr2 : arr1"
              alt=""
              @click="openList(item, index)"
            />
          </div>
          <div
            v-show="item.show"
            v-for="(item2, index2) in item.child"
            :key="index2"
          >
            <div class="box-li-child flex ALcenter JCspaceBetween">
              <div @click="goMenuChild(item2)">{{ item2.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { menu } from "@/store/mdata.js";
export default {
  data() {
    return {
      menu: menu, // 菜单
      show: false, // 菜单控制
      arr1: require("@/assets/m/arr1.png"),
      arr2: require("@/assets/m/arr2.png"),
    };
  },
  methods: {
    /**
     * 打开弹窗
     */
    openShow() {
      this.show = true;
    },

    /**
     * 关闭弹窗
     */
    choseSHow() {
      this.show = false;
    },

    /**
     * 展开列表
     */
    openList(item, index) {
      // console.log(item, index);
      if (item.child.length > 0) {
        this.menu[index].show = !this.menu[index].show;
      }
    },

    /**
     * 返回云茗科技
     */
    openthedoor() {
      this.$router.push("/Mhome");
      this.scrollToTop();
    },

    /**
     * 前往对于页面
     */
    goMenu(e) {
      if (e.name == "云茗科技") {
        this.$router.push("/Mhome");
      } else if (e.name == "产品与服务") {
        this.$router.push("/Mchanpin");
      } else if (e.name == "解决方案") {
        this.$router.push("/Mfangan");
      } else if (e.name == "经典案例") {
        this.$router.push("/Manli");
      } else if (e.name == "关于我们") {
        // 无
        this.$router.push("/Mabout");

      }
      this.choseSHow();
      this.scrollToTop();
    },

    /**
     * 前往二级页面
     */
    goMenuChild(e) {
      if (e.name == "智慧社区平台") {
        this.$router.push("/Mchanpin");
      }

      this.choseSHow();
      this.scrollToTop();

    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },

    /**
     * 提交
     */
     goTijiao() {
      this.$router.push("/Mshenqing");
     },

  },
};
</script>

<style lang="less" scoped>
#mtabbar {
  .pof {
    width: 750px;
    height: 92px;
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 999;
    .pof-img {
      width: 245px;
      height: 48px;
      margin-left: 24px;
    }

    .pof-icon {
      width: 40px;
      height: 40px;
      margin-right: 40px;
    }

    .pof-box {
      width: 168px;
      height: 92px;
      line-height: 92px;
      background: #038ef2;
      font-size: 28px;
    }
  }

  .hezi {
    width: 750px;
    height: 92px;
  }

  .box {
    width: 750px;
    .box-li {
      width: 690px;
      height: 116px;
      margin: 0 30px;
      border-bottom: 2px solid #f5f5f5;
      font-size: 28px;
      .box-li-icon {
        width: 28px;
        height: 28px;
      }
    }
    .box-li-child {
      width: 640px;
      margin-left: 50px;
      height: 116px;
      border-bottom: 2px solid #f5f5f5;
      font-size: 28px;
    }
  }
}
</style>