<template>
  <div id="Tabbar">
    <div class="imgbox">
      <div class="zhan"></div>
      <div class="title tac">欢迎加入我们，一起创造未来，共享美好生活！</div>
      <div class="btnbox flex JCcenter">
        <div class="btnbox-btn tac hand-cursor" @click="opendialogshow">
          立即咨询
        </div>
      </div>
    </div>
    <div class="bottombox">
      <div class="top flex JCspaceBetween">
        <div class="box1">
          <div class="box1-title">产品与服务</div>
          <div
            class="box1-li hand-cursor"
            v-for="(item, index) in chanpin"
            :key="index"
            @click="goFun(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="box2">
          <div class="box2-title">解决方案</div>
          <div class="box2-libox flex fwW">
            <div
              class="box2-libox-li hand-cursor"
              v-for="(item, index) in hangyej.child"
              :key="index"
              @click="goFun(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="box1">
          <div class="box1-title">关于我们</div>
          <div
            class="box1-li hand-cursor"
            v-for="(item, index) in weYun"
            :key="index"
            @click="goFun(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="box3">
          <div class="box3-title">联系我们</div>
          <div class="box3-text">
            公司地址 浙江省杭州市上城区彭埠街道中豪五星国际4幢9楼
          </div>
          <div class="box3-text">电话联系 0571-56669944</div>
        </div>
        <div class="box4">
          <img class="box4-img" :src="icon" alt="" />
          <div class="box4-text tac">官方微信公众号</div>
        </div>
      </div>
      <div class="bot">
        <div class="bot-tit tac">
          @Copyrights 2023-2024 浙江云茗科技有限公司
        </div>
        <div class="bot-tit tac hand-cursor" @click="gobei">
          浙ICP备2023023392号-1
        </div>
      </div>
    </div>
    <dialogbox></dialogbox>
  </div>
</template>

<script>
import dialogbox from "./dialogbox.vue";
import { chanpin, weYun, hangyej } from "@/store/datat.js";
export default {
  name: "tabbar",
  components: {
    dialogbox,
  },
  data() {
    return {
      chanpin,
      weYun,
      hangyej,
      icon: require("@/assets/icon/weixin.png"),
    };
  },
  methods: {
    opendialogshow() {
      // console.log(this.$store.state.dialogshow, "this.$store.state.dialogshow");
      let ac = true;
      this.$store.commit("changedialogshow", ac);
    },
    goFun(e) {
      // console.log(e.name);
      if (e.name == "智慧社区") {
        this.$router.push("/Hfangan");
        // this.listId = 3;
      } else if(e.name == "智慧社区平台") {
        this.$router.push("/Hchanpin");
        // this.listId = 2;
      }
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },

    gobei() {
      window.open("https://beian.miit.gov.cn", "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
#Tabbar {
  width: 1920px;
  .imgbox {
    width: 1920px;
    height: 340px;
    background: url(~@/assets/bg/bottom1.png) no-repeat;
    background-size: 100% 100%;
    .zhan {
      height: 112px;
    }
    .title {
      width: 1920px;
      height: 50px;
      font-size: 36px;
      font-weight: 600;
      color: #353c58;
      line-height: 42px;
    }
    .btnbox {
      width: 1920px;
      margin-top: 20px;
      .btnbox-btn {
        width: 160px;
        height: 46px;
        background: #038ef2;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        line-height: 46px;
      }
    }
  }

  .bottombox {
    width: 1920px;
    background: #f2f9ff;

    .top {
      width: 1440px;
      margin: 0 240px;
      height: 314px;
      border-bottom: 1px solid #dfe8ee;

      .box1 {
        width: 180px;
        margin: 34px 0 0 0;

        .box1-title {
          width: 180px;
          height: 28px;
          font-weight: 600;
          font-size: 20px;
          color: #353c58;
          margin-bottom: 16px;
        }
        .box1-li {
          width: 180px;
          font-size: 14px;
          color: #64698b;
          margin-bottom: 12px;
        }
      }

      .box2 {
        width: 330px;
        margin: 34px 0 0 0;

        .box2-title {
          width: 330px;
          height: 28px;
          font-weight: 600;
          font-size: 20px;
          color: #353c58;
          margin-bottom: 16px;
        }

        .box2-libox {
          width: 330px;
          .box2-libox-li {
            width: 163px;
            font-size: 14px;
            color: #64698b;
            margin-bottom: 12px;
          }
        }
      }

      .box3 {
        width: 280px;
        margin: 34px 0 0 0;
        .box3-title {
          width: 280px;
          height: 28px;
          font-weight: 600;
          font-size: 20px;
          color: #353c58;
          margin-bottom: 16px;
        }

        .box3-text {
          width: 280px;
          font-size: 14px;
          color: #64698b;
          line-height: 16px;
          margin-bottom: 12px;
        }
      }

      .box4 {
        width: 120px;
        margin: 34px 0 0 0;
        .box4-img {
          width: 120px;
          height: 120px;
          margin-bottom: 12px;
        }

        .box4-text {
          width: 120px;
          font-size: 12px;
          color: #64698b;
        }
      }
    }

    .bot {
      width: 1440px;
      margin: 0 240px;
      height: 103px;

      .bot-tit {
        width: 1440px;
        height: 103px;
        line-height: 103px;
        height: 17px;
        font-size: 12px;
        color: #353c58;
      }
    }
  }
}
</style>